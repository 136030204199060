var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('user-list-add-new', {
    attrs: {
      "is-add-new-user-sidebar-active": _vm.isAddNewUserSidebarActive,
      "role-options": _vm.roleOptions,
      "plan-options": _vm.planOptions
    },
    on: {
      "update:isAddNewUserSidebarActive": function updateIsAddNewUserSidebarActive($event) {
        _vm.isAddNewUserSidebarActive = $event;
      },
      "update:is-add-new-user-sidebar-active": function updateIsAddNewUserSidebarActive($event) {
        _vm.isAddNewUserSidebarActive = $event;
      },
      "refetch-data": _vm.refetchData
    }
  }), _c('users-list-filters', {
    attrs: {
      "role-filter": _vm.roleFilter,
      "plan-filter": _vm.planFilter,
      "status-filter": _vm.statusFilter,
      "role-options": _vm.roleOptions,
      "plan-options": _vm.planOptions,
      "status-options": _vm.statusOptions
    },
    on: {
      "update:roleFilter": function updateRoleFilter($event) {
        _vm.roleFilter = $event;
      },
      "update:role-filter": function updateRoleFilter($event) {
        _vm.roleFilter = $event;
      },
      "update:planFilter": function updatePlanFilter($event) {
        _vm.planFilter = $event;
      },
      "update:plan-filter": function updatePlanFilter($event) {
        _vm.planFilter = $event;
      },
      "update:statusFilter": function updateStatusFilter($event) {
        _vm.statusFilter = $event;
      },
      "update:status-filter": function updateStatusFilter($event) {
        _vm.statusFilter = $event;
      }
    }
  }), _c('b-card', {
    staticClass: "mb-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "m-2"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-start mb-1 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('label', [_vm._v("Show")]), _c('v-select', {
    staticClass: "per-page-selector d-inline-block mx-50",
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.perPageOptions,
      "clearable": false
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  }), _c('label', [_vm._v("entries")])], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-1",
    attrs: {
      "placeholder": "Search..."
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.isAddNewUserSidebarActive = true;
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v("Add User")])])], 1)])], 1)], 1), _c('b-table', {
    ref: "refUserListTable",
    staticClass: "position-relative",
    attrs: {
      "items": _vm.fetchUsers,
      "responsive": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "sort-by": _vm.sortBy,
      "show-empty": "",
      "empty-text": "No matching records found",
      "sort-desc": _vm.isSortDirDesc
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(user)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "vertical-align": "center"
          },
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-avatar', {
                attrs: {
                  "size": "32",
                  "src": data.item.avatar,
                  "text": _vm.avatarText(data.item.fullName),
                  "variant": "light-".concat(_vm.resolveUserRoleVariant(data.item.role)),
                  "to": {
                    name: 'apps-users-view',
                    params: {
                      id: data.item.id
                    }
                  }
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap",
          attrs: {
            "to": {
              name: 'apps-users-view',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.item.fullName) + " ")]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v("@" + _vm._s(data.item.username))])], 1)];
      }
    }, {
      key: "cell(role)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          class: "text-".concat(_vm.resolveUserRoleVariant(data.item.role)),
          attrs: {
            "icon": _vm.resolveUserRoleIcon(data.item.role),
            "size": "18"
          }
        }), _c('span', {
          staticClass: "align-text-top text-capitalize"
        }, [_vm._v(_vm._s(data.item.role))])], 1)];
      }
    }, {
      key: "cell(status)",
      fn: function fn(data) {
        return [_c('b-badge', {
          staticClass: "text-capitalize",
          attrs: {
            "pill": "",
            "variant": "light-".concat(_vm.resolveUserStatusVariant(data.item.status))
          }
        }, [_vm._v(" " + _vm._s(data.item.status) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(data) {
        return [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "no-caret": "",
            "right": _vm.$store.state.appConfig.isRTL
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "align-middle text-body",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          attrs: {
            "to": {
              name: 'apps-users-view',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "FileTextIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v("Details")])], 1), _c('b-dropdown-item', {
          attrs: {
            "to": {
              name: 'apps-users-edit',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v("Edit")])], 1), _c('b-dropdown-item', [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v("Delete")])], 1)], 1)];
      }
    }])
  }), _c('div', {
    staticClass: "mx-2 mb-2"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center justify-content-sm-start",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v("Showing " + _vm._s(_vm.dataMeta.from) + " to " + _vm._s(_vm.dataMeta.to) + " of " + _vm._s(_vm.dataMeta.of) + " entries")])]), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center justify-content-sm-end",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalUsers,
      "per-page": _vm.perPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }